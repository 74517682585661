/* eslint-disable jsx-a11y/media-has-caption */
import ShineBorder from "~/components/shine-border";

import { Card } from "@nextui-org/card";
import { Modal, ModalContent, useDisclosure } from "@nextui-org/modal";
import posthog from "posthog-js";
import { track } from "~/utils/track";
import { LuPlayCircle } from "react-icons/lu";
import { PUBLIC_TRIBE_CLOUDFRONT_URL } from "~/utils/constants";

export default function HowItWorks() {
  const { isOpen, onOpen, onOpenChange } = useDisclosure();

  return (
    <>
      <Card
        className="-translate-y-1/2 rounded-full shadow-lg md:shadow-xl"
        isPressable
        onPress={() => {
          posthog.capture("how_it_works_opened");
          track("how_it_works_opened");
          onOpen();
        }}
      >
        <ShineBorder
          borderRadius={48}
          borderWidth={4}
          className="relative flex h-20 w-28 flex-col items-center justify-center overflow-hidden rounded-full bg-background"
          color={["#A07CFE", "#FE8FB5", "#FFBE7B"]}
        >
          <div className="flex justify-center items-center px-8 py-6 rounded-full cursor-pointer">
            <LuPlayCircle className="w-8 h-8 mr-4 text-foreground" />
            <span className="text-center text-xl sm:text-2xl font-medium text-foreground">
              How it works?
            </span>
          </div>
        </ShineBorder>
      </Card>

      <Modal
        isOpen={isOpen}
        onOpenChange={onOpenChange}
        placement="center"
        size="xs"
        backdrop="blur"
      >
        <ModalContent>
          <video
            playsInline
            autoPlay
            src={`${PUBLIC_TRIBE_CLOUDFRONT_URL}/assets/works.mp4`}
            controls
            className="max-h-[90dvh] aspect-[9/16] w-auto object-contain"
          />
        </ModalContent>
      </Modal>
    </>
  );
}
