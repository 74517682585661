import { cn } from "@nextui-org/react";
import { Link } from "@remix-run/react";
import { track } from "~/utils/track";
import posthog from "posthog-js";
import { LuArrowRight } from "react-icons/lu";
import { useCallback } from "react";

export type Suggestion = {
  type: "category" | "query";
  value: string;
  image?: string;
  color?: string;
};

export default function SuggestionCard({
  suggestion,
}: {
  suggestion: Suggestion;
}) {
  const handleClick = useCallback(async () => {
    const trackMeta = async () => {
      await import("react-facebook-pixel")
        .then((x) => x.default)
        .then((ReactPixel) => {
          ReactPixel.track("Search", {
            search_string: suggestion.value,
            currency: "INR",
            value: 10,
          });
        });
    };

    await trackMeta();

    posthog.capture("sample_search", {
      type: "category",
      query: suggestion.value,
    });

    track("sample_search", {
      type: "category",
      query: suggestion.value,
    });
  }, [suggestion.value]);

  if (suggestion.type === "category") {
    return (
      <Link
        to={`/search?q=${encodeURIComponent(suggestion.value)}`}
        prefetch="none"
        onClick={handleClick}
      >
        <div className="transform-gpu">
          <div
            className={cn(
              "w-[155px] h-[155px] md:w-[200px] md:h-[200px] rounded-xl relative overflow-hidden group"
            )}
          >
            <img
              loading="lazy"
              className="w-full h-full object-cover rounded-md"
              alt={suggestion.value}
              src={suggestion.image}
            />
            <div className="absolute inset-0 flex justify-center items-center p-2 md:p-4 bg-black bg-opacity-30 group-hover:bg-opacity-50 transition-all duration-300">
              <p className="text-center font-bold text-lg text-white drop-shadow-[0_2px_2px_rgba(0,0,0,0.8)]">
                {suggestion.value}
              </p>
            </div>
          </div>
        </div>
      </Link>
    );
  }

  return (
    <Link
      to={`/search?q=${encodeURIComponent(suggestion.value)}`}
      onClick={() => {
        posthog.capture("sample_search", {
          type: "longtail",
          query: suggestion.value,
        });
        track("sample_search", {
          type: "longtail",
          query: suggestion.value,
        });
      }}
    >
      <div className="transform-gpu">
        <div
          className={cn(
            "w-[155px] h-[155px] md:w-[200px] md:h-[200px] p-2 md:p-4 rounded-xl flex flex-col items-end",
            `bg-gradient-to-br from-${suggestion.color}-500 to-${suggestion.color}-800 text-${suggestion.color}-100`
          )}
        >
          <p className="flex-1 font-medium">{suggestion.value}</p>
          <div
            className={cn(
              "bg-white rounded-full p-1",
              `text-${suggestion.color}-800`
            )}
          >
            <LuArrowRight />
          </div>
        </div>
      </div>
    </Link>
  );
}
