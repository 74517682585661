import type { MetaFunction } from "@remix-run/node";
import { Divider } from "@nextui-org/react";
import { HiSparkles } from "react-icons/hi2";

import Search from "~/components/search";
import Suggestions from "~/components/suggestions";
import HowItWorks from "~/components/how-it-works";
import Navbar from "~/components/navbar";
import { PrefetchPageLinks } from "@remix-run/react";
import { OG_METADATA } from "~/utils/constants";

export const meta: MetaFunction = () => {
  return [
    { title: "Tribe - AI that finds any service expert ✨" },
    {
      name: "description",
      content:
        "Discover India's top service providers on Tribe! From photographers to interior designers and fitness trainers, find the perfect match for your needs today.",
    },
    ...OG_METADATA,
  ];
};

export default function Index() {
  return (
    <>
      <Navbar hideLogo={true} />
      <section className="flex flex-col items-center relative">
        <div className="hidden md:block bg-gradient-radial from-background via-background to-transparent blur-3xl w-[800px] h-[800px] rounded-full  absolute -top-[400px] left-1/2 transform -translate-x-1/2  z-10"></div>

        <div className="inline-block max-w-xl w-full px-4 text-center justify-center z-10 md:absolute md:top-1/3 md:left-1/2 md:transform md:-translate-x-1/2 md:-translate-y-1/3 mt-12 md:mt-0 mb-4 md:mb-0">
          <h1 className="text-5xl md:text-8xl font-black text-primary mb-6 md:mb-16 animate-in slide-in-from-top fade-in-0 duration-500 md:drop-shadow-md">
            tribe
          </h1>
          <div className="bg-background bg-opacity-40 p-2 rounded-full backdrop-blur-md">
            <div className="bg-background rounded-full">
              <Search placeholder="Ask AI anything..." />
            </div>
          </div>
          <p className="md:hidden text-xl font-medium leading-9 mt-8 mb-8">
            Find any service expert with{" "}
            <span className="text-primary font-bold inline-flex items-center">
              AI <HiSparkles className="ml-1" />
            </span>
          </p>
        </div>

        <Suggestions />
      </section>

      <div className="flex flex-col items-center pt-24">
        <div className="w-full flex flex-col items-center">
          <Divider />
          <HowItWorks />
        </div>
      </div>
      <PrefetchPageLinks page="/search" />
    </>
  );
}
