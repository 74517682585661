import useEmblaCarousel from "embla-carousel-react";
import AutoScroll from "embla-carousel-auto-scroll";
import SuggestionCard, { Suggestion } from "./suggestion-card";

export default function SuggestionRow({
  suggestions,
  reverse,
}: {
  suggestions: Suggestion[];
  reverse: boolean;
}) {
  const [emblaRef] = useEmblaCarousel(
    {
      slidesToScroll: "auto",
      dragFree: true,
      loop: true,
    },
    [
      AutoScroll({
        speed: 0.7,
        playOnInit: true,
        stopOnInteraction: false,
        startDelay: 400,
        direction: reverse === true ? "backward" : "forward",
      }),
    ]
  );

  return (
    <div className="relative">
      <div ref={emblaRef} className="">
        <div className="flex gap-2 px-2 py-1">
          {suggestions.map((suggestion) => (
            <SuggestionCard key={suggestion.value} suggestion={suggestion} />
          ))}
        </div>
      </div>
    </div>
  );
}
