import Marquee from "~/components/marquee";
import SuggestionCard, { Suggestion } from "./suggestion-card";
import SuggestionRow from "./suggestion-row";
import SUGGESTIONS from "./suggestions.json";

const SUGGESTIONS_PHONE = [
  SUGGESTIONS.slice(0, Math.ceil(SUGGESTIONS.length / 2) - 1),
  SUGGESTIONS.slice(SUGGESTIONS.length / 2),
];

const SUGGESTIONS_COMPUTER = [
  SUGGESTIONS.slice(0, Math.ceil(SUGGESTIONS.length / 3)),
  SUGGESTIONS.slice(
    Math.ceil(SUGGESTIONS.length / 3),
    Math.ceil(SUGGESTIONS.length / 3) * 2
  ),
  SUGGESTIONS.slice(Math.ceil(SUGGESTIONS.length / 3) * 2),
];

export default function Suggestions() {
  return (
    <>
      <div className="max-w-[100vw] flex flex-col overflow-hidden transition md:hidden">
        {SUGGESTIONS_PHONE.map((row, i) => (
          <SuggestionRow
            key={i}
            suggestions={row as Suggestion[]}
            reverse={i % 2 === 0}
          />
        ))}
      </div>
      <div className="max-w-full overflow-hidden hidden md:block">
        {SUGGESTIONS_COMPUTER.map((row, i) => (
          <Marquee
            key={i}
            reverse={i % 2 === 0}
            pauseOnHover
            className="[--duration:75s]"
          >
            {row.map((suggestion) => (
              <SuggestionCard
                key={suggestion.value}
                suggestion={suggestion as Suggestion}
              />
            ))}
          </Marquee>
        ))}
      </div>
      <div className="hidden">
        <div className="w-4 h-4 bg-gradient-to-br from-cyan-500 to-cyan-800 text-cyan-100">
          <div className="text-cyan-800" />
        </div>
        <div className="w-4 h-4 bg-gradient-to-br from-green-500 to-green-800 text-green-100 ">
          <div className="text-green-800" />
        </div>
        <div className="w-4 h-4 bg-gradient-to-br from-red-500 to-red-800 text-red-100 ">
          <div className="text-red-800" />
        </div>
        <div className="w-4 h-4 bg-gradient-to-br from-blue-500 to-blue-800 text-blue-100">
          <div className="text-blue-800" />
        </div>
        <div className="w-4 h-4 bg-gradient-to-br from-yellow-500 to-yellow-800 text-yellow-100">
          <div className="text-yellow-800" />
        </div>
        <div className="w-4 h-4 bg-gradient-to-br from-pink-500 to-pink-800 text-pink-100">
          <div className="text-pink-800" />
        </div>
        <div className="w-4 h-4 bg-gradient-to-br from-purple-500 to-purple-800 text-purple-100">
          <div className="text-purple-800" />
        </div>
        <div className="w-4 h-4 bg-gradient-to-br from-zinc-500 to-zinc-800 text-zinc-100">
          <div className="text-zinc-800" />
        </div>
        <div className="w-4 h-4 bg-gradient-to-br from-rose-500 to-rose-800 text-rose-100">
          <div className="text-rose-800" />
        </div>
        <div className="w-4 h-4 bg-gradient-to-br from-amber-500 to-amber-800 text-amber-100">
          <div className="text-amber-800" />
        </div>
        <div className="w-4 h-4 bg-gradient-to-br from-teal-500 to-teal-800 text-teal-100">
          <div className="text-teal-800" />
        </div>
        <div className="w-4 h-4 bg-gradient-to-br from-lime-500 to-lime-800 text-lime-100">
          <div className="text-lime-800" />
        </div>
        <div className="w-4 h-4 bg-gradient-to-br from-fuchsia-500 to-fuchsia-800 text-fuchsia-100">
          <div className="text-fuchsia-800" />
        </div>
        <div className="w-4 h-4 bg-gradient-to-br from-indigo-500 to-indigo-800 text-indigo-100">
          <div className="text-indigo-800" />
        </div>
        <div className="w-4 h-4 bg-gradient-to-br from-emerald-500 to-emerald-800 text-emerald-100">
          <div className="text-emerald-800" />
        </div>
        <div className="w-4 h-4 bg-gradient-to-br from-orange-500 to-orange-800 text-orange-100">
          <div className="text-orange-800" />
        </div>
      </div>
    </>
  );
}
